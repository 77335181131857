<template>
  <div  id="mycard" class="card">
    <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
      <div class="form-group row">
        <label class="col-md-1 col-form-label font-weight-semibold">GST 3 B:</label>

        <label class="col-md-1 col-form-label" style="color: green;">Month:</label>
        <div class="col-md-2">
          <input type="month"  class="form-control" placeholder="Upto Date" v-model="upto_date" >
        </div>
        <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData">Show</button>
      </div>

    </div>

    <div class="table-responsive">
      <table  id="summary-table" class="table table-xs table-columned table-hover" data-target=".context-table">
        <thead>
        <tr>
          <th style="width: 50px;">S.No</th>
          <th>Purticulards</th>
          <th style="width: 200px;text-align: right">Taxable Value</th>
          <th style="width: 200px;text-align: right">IGST</th>
          <th style="width: 200px;text-align: right">CGST</th>
          <th style="width: 200px;text-align: right">SGST</th>
        </tr>
        </thead>
        <tbody   >
        </tbody>
        <tfoot style="background-color: lightgrey;" class="font-weight-semibold">

        </tfoot>
      </table>
    </div>




    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <a href="#" class="dropdown-item" ><i class="icon-file-eye"></i>Preview</a>

      </div>
    </div>
    <!-- End of Context Menu -->

  </div>
</template>

<script>
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'gst3bview',
    store,
    data () {
      return {
        mytable: {},
        groups: [],
        purchase:[],
        ldgp: 0,
        from_date: moment().format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
      }
    },
    created () {

    },
    filters:{
      formatDate(val){
        return val.slice(0, 10).split('-').reverse().join('-');
      },
      indianFormat (value) {
        if(value !== 0)
          return value.toFixed(2);
        else
          return '';
      },
      formatDetail(ldgp, val){
        var result = '<table>';
        val.forEach(function (v) {
          if(v.ledger.code != ldgp){
            result +=  '<tr><td>'+ v.ledger.name +'</td></tr>';
          }
        });
        result += '<table>';
        return result;
      }

    },
    mounted () {
      let self = this;
      self.$data.ldgp = 0;
      self.$data.mytable = $('#summary-table').children('tbody');



      $('.table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text()

          if ($(e.target).text() === 'Refresh') {
            // self.loadData()
          }
        }
      });

    },
    methods: {

      blockCard(){
        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });
      },
      unblockCard(){
        $('#mycard').unblock();
      },

      loadData () {

        let self = this;
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }


        self.blockCard();

        let imp_goods =  { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let imp_services = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let rcm = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let itc = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let ieli = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let sale = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let exp = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };

        let compo = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let nil = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let exempt = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let nongst = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };



        $("#summary-table > tbody tr").remove();
        $("#summary-table > tfoot tr").remove();
        self.$data.trans = [];


        fetch(`${process.env.VUE_APP_ROOT_API}v1/gst/3b/?month=${self.$data.upto_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.accepted) {


              if (_.isArray(resp.data)) {

                self.$data.trans = resp.data;

                self.$data.trans.forEach( (trn) => {


                  if (trn.type == 305 && trn.nature == 3){
                    imp_services.taxable += trn.taxable_amt;
                    imp_services.igst += trn.igst;
                    imp_services.cgst += trn.cgst;
                    imp_services.sgst += trn.sgst;
                  }else if (trn.type == 305 ){
                    imp_goods.taxable += trn.taxable_amt;
                    imp_goods.igst += trn.igst;
                    imp_goods.cgst += trn.cgst;
                    imp_goods.sgst += trn.sgst;
                  }else if (trn.type == 308 ){
                    rcm.taxable += trn.taxable_amt;
                    rcm.igst += trn.igst;
                    rcm.cgst += trn.cgst;
                    rcm.sgst += trn.sgst;
                  }else if (trn.type == 301 ){

                    itc.taxable += trn.taxable_amt;
                    itc.igst += trn.igst;
                    itc.cgst += trn.cgst;
                    itc.sgst += trn.sgst;
                  }else if (trn.type == 303 ){  //Composition

                    if (trn.state == store.state.user.branch.state_cd){
                      compo.cgst += trn.taxable_amt;
                    }else{
                      compo.sgst += trn.taxable_amt;
                    }

                  }else if (trn.type == 304 ){  //Exempted

                    if (trn.state == store.state.user.branch.state_cd){
                      exempt.cgst += trn.taxable_amt;
                    }else{
                      exempt.sgst += trn.taxable_amt;
                    }
                  }else if (trn.type == 306 ){  //Non GST
                    if (trn.state == store.state.user.branch.state_cd){
                      nongst.cgst += trn.taxable_amt;
                    }else{
                      nongst.sgst += trn.taxable_amt;
                    }
                  }else if (trn.type == 307 ){
                    ieli.taxable += trn.taxable_amt;
                    ieli.igst += trn.igst;
                    ieli.cgst += trn.cgst;
                    ieli.sgst += trn.sgst;
                  }else if (trn.type == 404 || trn.type == 405 || trn.type == 407 || trn.type == 408 ){  //Sale
                    sale.taxable += trn.taxable_amt;
                    sale.igst += trn.igst;
                    sale.cgst += trn.cgst;
                    sale.sgst += trn.sgst;
                  }else if (trn.type == 406 ){  //Export
                    exp.taxable += trn.taxable_amt;
                    exp.igst += trn.igst;
                    exp.cgst += trn.cgst;
                    exp.sgst += trn.sgst;
                  }

                });


                $("#summary-table > tbody").append(`<tr style="background-color: lightgrey;"><td colspan="6" > <h5>Purchase</h5> </td></tr>`);

                let row = `<tr>
                      <td>1</td>
                      <td>Import of Goods</td>
                      <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_goods.taxable))}</td>
                      <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_goods.igst))}</td>
                      <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_goods.cgst))}</td>
                      <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_goods.sgst))}</td>
                   </tr>`;
                $("#summary-table > tbody").append(row);

                row = `<tr>
                  <td>2</td>
                  <td>Import of Services</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_services.taxable))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_services.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_services.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_services.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                row = `<tr>
                  <td>3</td>
                  <td>RCM</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.taxable))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                row = `<tr>
                  <td>4</td>
                  <td>All Other ITC</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(itc.taxable))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(itc.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(itc.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(itc.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                row = `<tr>
                  <td>5</td>
                  <td>Ineligible ITC</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(ieli.taxable))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(ieli.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(ieli.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(ieli.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                //Sales
                $("#summary-table > tbody").append(`<tr style="background-color: lightgrey;"><td colspan="6" > <h5>Supply of Goods</h5> </td></tr>`);


                row = `<tr>
                  <td>1</td>
                  <td>Sales</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(sale.taxable))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(sale.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(sale.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(sale.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                row = `<tr>
                  <td>2</td>
                  <td>Export Sales</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(exp.taxable))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(exp.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(exp.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(exp.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                row = `<tr>
                  <td>3</td>
                  <td>RCM</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.taxable))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                // Exempted Purchases
                $("#summary-table > tbody").append(`<tr style="background-color: lightgrey;"><td colspan="4" > <h5>Exempted Purchases</h5> </td> <td>Intra State</td> <td>Inter State</td> </tr>`);
                row = `<tr>
                  <td>1</td>
                  <td>Composition</td>
                  <td style="width: 200px;text-align: right"></td>
                  <td style="width: 200px;text-align: right"></td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(compo.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(compo.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                row = `<tr>
                  <td>2</td>
                  <td>Nil Rated</td>
                  <td style="width: 200px;text-align: right"></td>
                  <td style="width: 200px;text-align: right"></td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(nil.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(nil.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

                row = `<tr>
                  <td>3</td>
                  <td>Exempted</td>
                  <td style="width: 200px;text-align: right"></td>
                  <td style="width: 200px;text-align: right"></td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(exempt.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(exempt.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);


                row = `<tr>
                  <td>4</td>
                  <td>Non GST</td>
                  <td style="width: 200px;text-align: right"></td>
                  <td style="width: 200px;text-align: right"></td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(nongst.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(nongst.sgst))}</td>
               </tr>`;
                $("#summary-table > tbody").append(row);

              }

            } else {
              swal({ title: 'Oops!', text: resp.message, type: 'error' })
            }
          }).catch(function (err) {
            swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
          }).finally(function () {

            self.unblockCard();
          });

      },
      purchaseDetails(){
        let self = this;

        let imp_goods =  { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let imp_services = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let rcm = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let itc = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };
        let ieli = { taxable:0.0, igst:0.0, cgst:0.0, sgst:0.0 };



        this.$data.trans.forEach( (trn) => {


          if (trn.type == 305 && trn.nature == 3){
            imp_services.taxable += trn.taxable_amt;
            imp_services.igst += trn.igst;
            imp_services.cgst += trn.cgst;
            imp_services.sgst += trn.sgst;
          }else if (trn.type == 305 ){
            imp_goods.taxable += trn.taxable_amt;
            imp_goods.igst += trn.igst;
            imp_goods.cgst += trn.cgst;
            imp_goods.sgst += trn.sgst;
          }else if (trn.type == 308 ){
            rcm.taxable += trn.taxable_amt;
            rcm.igst += trn.igst;
            rcm.cgst += trn.cgst;
            rcm.sgst += trn.sgst;
          }else if (trn.type == 301 ){

            itc.taxable += trn.taxable_amt;
            itc.igst += trn.igst;
            itc.cgst += trn.cgst;
            itc.sgst += trn.sgst;
          }else if (trn.type == 307 ){
            ieli.taxable += trn.taxable_amt;
            ieli.igst += trn.igst;
            ieli.cgst += trn.cgst;
            ieli.sgst += trn.sgst;
          }

        });

        $("#summary-table > tbody tr").remove();
        let row = `<tr>
                      <td>1</td>
                      <td>Import of Goods</td>
                      <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_goods.igst))}</td>
                      <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_goods.cgst))}</td>
                      <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_goods.sgst))}</td>
                   </tr>`;
        $("#summary-table > tbody").append(row);

        row = `<tr>
                  <td>2</td>
                  <td>Import of Services</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_services.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_services.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(imp_services.sgst))}</td>
               </tr>`;
        $("#summary-table > tbody").append(row);

        row = `<tr>
                  <td>3</td>
                  <td>RCM</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(rcm.sgst))}</td>
               </tr>`;
        $("#summary-table > tbody").append(row);

        row = `<tr>
                  <td>4</td>
                  <td>All Other ITC</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(itc.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(itc.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(itc.sgst))}</td>
               </tr>`;
        $("#summary-table > tbody").append(row);

        row = `<tr>
                  <td>5</td>
                  <td>Ineligible ITC</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(ieli.igst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(ieli.cgst))}</td>
                  <td style="width: 200px;text-align: right">${indianFormat(Math.abs(ieli.sgst))}</td>
               </tr>`;
        $("#summary-table > tbody").append(row);
      }
    }
  }

</script>

<style scoped>

</style>
